
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import React, { useEffect } from 'react';
import isNode from 'detect-node';
import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';
import Cookies from 'universal-cookie';
import FonomaDefaultSeo from '../components/seo/fonoma-default-seo';
import { FonomaGlobalStyle } from '../global-styles/fonoma-global-style';
import { cookieResolverFactory, defaultAuthorizationProvider, } from '../lib/resources/authorization';
import { analytics } from '../modules/analytics/analytics';
import { FonomaBaseTheme } from '../modules/common';
import { AutologinHandler } from '../modules/person/auto-login/autologin-handler';
import { PersonProvider } from '../modules/person/context';
import { personResource } from '../modules/person/person-resource';
import { defaultInitialState, PersonState } from '../modules/person/state';
import { FONOMA_ENVS } from '../modules/util/constants';
import { initDefaultPreferences } from '../modules/util/cookie-preferences/init-default-preferences';
import { defaultPreferences } from '../modules/util/cookie-preferences/preferences';
import { getEventName } from '../modules/util/web-metric/get-event-name';
import { Metric } from '../modules/util/web-metric/metric-interface';
import { GlobalLoadingIndicatorProvider } from '../modules/global-loading-indicator/context';
import { ErrorBoundary, Provider } from '@rollbar/react';
import { rollbarConfig } from '../modules/rollbar/rollbar-config';
import { RollbarPersonHandler } from '../modules/rollbar/rollbar-person-handler';
import { AnalyticsScript } from '../lib/analytics/analytics-script';
import { getPersonIdFromCookies } from '../modules/person/get-person-id-from-cookies';
import { IncomingMessage } from 'http';
import { RetopeBaseTheme } from '../modules/common/styles/themes/retope-base-theme';
import { isRetope } from '../modules/util/utilities/is-retope';
/**
 * Custom Nextjs App component to enable i18n. Injects the FonomaBaseTheme to all child components.
 */
export function FonomaApp({ Component, pageProps, }: AppProps<{
    personState: PersonState;
}>): JSX.Element {
    const { personState, ...rest } = pageProps;
    // set up default prefs
    // run only once
    useEffect(() => {
        initDefaultPreferences(new Cookies(document.cookie), defaultPreferences);
    }, []);
    const BaseTheme = isRetope() ? RetopeBaseTheme : FonomaBaseTheme;
    return (<Provider config={rollbarConfig}>
            <ErrorBoundary>
                <ThemeProvider theme={BaseTheme}>
                    <GlobalLoadingIndicatorProvider initialState={{
            show: false,
            opacity: 0.75,
            color: '#ffffff',
        }}>
                        <PersonProvider initialState={personState}>
                            <RollbarPersonHandler />
                            <AutologinHandler />
                            <FonomaDefaultSeo />
                            <AnalyticsScript />
                            <Component {...rest}/>
                            <FonomaGlobalStyle />
                        </PersonProvider>
                    </GlobalLoadingIndicatorProvider>
                </ThemeProvider>
            </ErrorBoundary>
        </Provider>);
}
FonomaApp.getInitialProps = async (ctx: AppContext): Promise<AppInitialProps> => {
    console.log('getInitialProps');
    const { pageProps } = await App.getInitialProps(ctx);
    let personState = defaultInitialState;
    const req: IncomingMessage = ctx.ctx.req;
    if (req) {
        const cookies = new Cookies(req.headers.cookie);
        cookies &&
            defaultAuthorizationProvider.setAuthzResolver(cookieResolverFactory(cookies));
        const personData = getPersonIdFromCookies(cookies);
        if (personData) {
            try {
                const id = personData.id;
                const person = await personResource.get(id, {
                    headers: { 'x-front-end': 'plj029gybp1qt' },
                });
                personState = {
                    ...personState,
                    person: person,
                    isLoggedIn: true,
                };
            }
            catch (e) {
                console.error(e);
                personState = { ...personState, error: e };
            }
        }
    }
    return { pageProps: { ...pageProps, personState: personState } };
};
export function reportWebVitals(metric: Metric): void {
    if (!isNode) {
        if (process.env.NEXT_PUBLIC_PROJECT_ENV !== FONOMA_ENVS.PRODUCTION) {
            console.log(metric);
        }
        void analytics.track(getEventName(metric), {
            ...metric,
            ...{ url: window.location.pathname },
        });
    }
}
const __Next_Translate__Page__193e3954a4e__ = FonomaApp;

    export default __appWithI18n(__Next_Translate__Page__193e3954a4e__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  